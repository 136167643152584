<template>
  <base-section
    id="workshop"
    class="accent text-center"
  >
    <div class="bg-pixel" />
    <base-section-heading
      class="primary--text pt-16"
      title="Workshops"
    />
    <v-container class="pr-16 pl-16">
      <p

        class="text-h5 font-weight-bold mb-4 text-center"
      >
        Wir bringen jahrelange Erfahrungen und Knowhow mit und freuen uns auf ihre Anfrage!
        Sehr gerne unterbreiten wir Ihnen für ihre Bedürfnisse ein individuelles Angebot.
      </p>
    </v-container>
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="8"
        >
          <v-expansion-panels
            flat
            class="hello"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                Schule & Kultur
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content text-left">
                Seit 2003 werden wir regelmässig von Schule & Kultur angefragt für Workshops, Projekttage oder ganze
                Projektwochen an diversen Schulen in diversen Kantonen.<br><br>
                Sehr gerne unterbreiten wir Ihnen für ihre Bedürfnisse ein individuelles Angebot:<br>

                <div class="text-h6 text-style secondary--text text-left">
                  <a href="">www.schuleundkultur.zh</a>
                </div>
              </v-expansion-panel-content>
              <v-divider
                horizontal
                class="divider mx-6"
              />
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                Ferienkurse
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content text-left">
                Regelmässig bieten wir ganzwöchige Ferienkurse über das Schul- und Sportdepartement Zürich im Dynamo an:<br>
                <div class="text-h6 text-style secondary--text text-left">
                  <a href=""> www.sportamt.ch/kurse</a>
                </div>
              </v-expansion-panel-content>
              <v-divider
                horizontal
                class="divider mx-6"
              />
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                Weitere Kurse
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content text-left">
                Individuell und auf die Wünsche angepasst bieten wir Workshops und Projekttage oder ganze Projektwochen an.
                Sehr gerne unterbreiten wir Ihnen für ihre Bedürfnisse ein individuelles Angebot.
              </v-expansion-panel-content>
              <v-divider
                horizontal
                class="divider mx-6"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="mb-12"
          />

          <!--div class="text-center">
            <base-btn
              :tile="false"
              color="tertiary"
              href="https://store.vuetifyjs.com/products/zero-theme-pro/?ref=vtyd-pro-page-features"
              rounded
            >
              Kontakt
            </base-btn>
          </div-->
        </v-col>
      </v-row>
    </v-container>
    <div class="py-16" />
    <div class="bg-pixel-down" />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',
  }
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}

.list-header {
  font-size: 1.3rem !important;
  font-weight: 700;
}

.list-content {
  font-size: 1.1rem !important;
  font-weight: 600;
}

.divider {
  border-color: rgba(0, 0, 0, 0.72) !important;
  border-width: 2px 0 0 0;
  margin-botttom: 4px;
}

.bg-pixel {
  background-image: url('~@/assets/gradient_red.png');
  background-size: contain;
  background-repeat: repeat-x;
  height: 80px;
  width: 100%;
  position: absolute;
  z-index: 3;
}

.v-application .accent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-pixel-down {
  height: 80px;
  background-size: contain;
  margin-top: -80px;
  background-image: url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  transform: scaleY(-1);
  width: 100%;
  position: absolute;
  z-index: 3;
}
</style>
